﻿import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../login/login.service';
import {AuthService} from '../common/auth.service';

@Component({
    templateUrl: './main.component.html'    
})

export class MainComponent {
    
}