﻿import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'umr-public-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
    toCopyright: number = new Date().getFullYear();
}