﻿import {Injectable, Inject} from '@angular/core';
import {CookieService, CookieOptions, CookieOptionsArgs, BaseCookieOptions, ANGULAR2_COOKIE_PROVIDERS} from 'angular2-cookie/core';

@Injectable()
export class UmrPublicCookieService {

    constructor(private _cookieService: CookieService) { }

    getAllCookies(): Object {
        return this._cookieService.getAll();
    }

    getCookie(key: string): string {
        return this._cookieService.get(key);
    }

    setCookie(key: string, value: string, options?: CookieOptionsArgs):void {
        if (options) {
            this._cookieService.put(key, value, options);
        } else {
            this._cookieService.put(key, value);
        }
    }

    getDeserializedObject(key: string): Object {
        return this._cookieService.getObject(key);
    }

    setSerializedObject(key: string, value: Object, options?: CookieOptionsArgs): void {
        if (options) {
            this._cookieService.putObject(key, value, options);
        } else {
            this._cookieService.putObject(key, value);
        }
    }

    removeCookie(key: string, options?: CookieOptionsArgs): void {
        if (options) {
            this._cookieService.remove(key, options);
        } else {
            this._cookieService.remove(key);
        }
    }
}